export default {
  init() {
    // JavaScript to be fired on all pages
    $('[data-toggle="tooltip"]').tooltip()

    $('.navigation--icon').click(function() {
      $(this).toggleClass('active'),
      $('.navigation').toggleClass('active')
    });

    // Sidebar readmore
    $('span.read-more').click(function() {
      $(this).toggleClass('active'),
      $('.sidebar--content').toggleClass('active')
    });

    // headerAffix
    $(function(){
      var stickyTop = $('.main-header').offset().top;

      $(document).scroll(function(){
        if ($(document).scrollTop() >= stickyTop) {
          $('.main-header').css({position: 'fixed', top: '0px'});
        } else {
          $('.main-header').css({position: 'absolute', top: 'unset'});
        }
      });
    });

    if (!sessionStorage.isVisited) {
      sessionStorage.isVisited = 'true'
      $('.modal.automatisch').modal('show')
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
