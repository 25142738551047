// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

// import then needed Font Awesome functionality
import fontawesome from '@fortawesome/fontawesome';
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
// add the imported icons to the library
fontawesome.config = {searchPseudoElements: true};
library.add(fas, fab);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();
